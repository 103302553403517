$sub-title-color: #212529;
.member-table {
    box-shadow: unset !important;
       
    span {
        font-size: 0.7rem;
    }

    thead th {
        padding: 3px 7px !important;
        background: #9568ff66 !important;
        span button{
            padding: 3px 7px !important;
        }

    }

    tbody td {
        padding: 8px 10px !important;
        font-size: 0.7rem;
    }

   
    .tss-1qjwhn0-MUIDataTableBody-emptyTitle {
        font-size: 0.75rem;
    }

a{
    text-decoration: none;
}
}

.retailer-table {
    td {
        padding: 5px 5px !important;
    }
}

th {
    button {
        width: max-content !important;
    }
}

.MuiTablePagination-root {
    overflow: unset !important;
}

.member-table button {
    font-size: 0.75rem !important;
    font-weight: bold;
    // background-color: #d3dfff;
    width: max-content;
    color: $sub-title-color;

}

.retailer-table {
    box-shadow: unset !important;

    .MuiTableCell-root,
    .inner-row p {
        font-size: 0.75rem !important;
    }

    .MuiTableCell-head button {
        color: #48494D;
        text-transform: none;
        font-weight: bold !important;
        font-size: 0.75rem !important;
    }
}

.retailer-table tr:nth-child(odd):not(.MuiTableRow-footer, .inner-row .MuiTableHead-root tr, .inner-row .MuiTableBody-root tr) {
    background-color: #f4f0ff;
}

.member-table {
    // width: 100%;
    img{
        width: 40px;
    }
}

.order-table tbody tr:hover {
    .edit-icon {
        display: block !important;
    }

    .edit-icon:hover {
        background: green;
    }

    .delete-icon:hover {
        background: #ff0000;
    }

    .delete-icon {
        display: block !important;
    }
}

.custom-table {
    tr td {
        padding: 5px 0px !important;
    }

    label {
        margin-bottom: 0px;
        color: #969696;
        font-size: 0.7rem;
    }

    p {
        font-size: 0.7rem;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}
.member-table tbody tr td{
    white-space: nowrap !important;
}

.shipping-row{
    width: 93% !important;
}
