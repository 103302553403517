:root{
  --primary : #ff0861;
  --bg-total : #baeefc;
  --bg-unassigned : #fcc4c4;
  --bg-assigned : #acc2f9;
  --bg-intransit : #f9dc93;
  --btn-disable : #a0a6ab;
  --dark : #1e1d1d;
}

.d-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.w-100{
  width: 100% !important;
}
.w-50{
  width: 50% !important;
}
html{
  background: #F6F6F6;
}
.body{
  background: #212529;
  color: #48494D;
 font-family: "roboto-regular", sans-serif;
  font-size: 0.875rem;
}

.main{
  float: right;
  margin-left: 20x;
  background: #212529;
 width: 100%;
 margin: 10px;
}

 .custom_card{
  width: calc(100% - 250px);
  z-index: 999;
  float: right;
  /* margin: 10px; */
  /* border-radius: 20px !important; */
}
.custom_card hr{
  width: 100%;
}
.mobile_custom_header {
  background: #fff;
  color: white;
  z-index: -1 !important;
  /* margin-top: 15px; */
  height: 60px;
.MuiToolbar-gutters{
  min-height: 60px;
}
}

.main_box{
  padding: 30px !important;
  overflow: auto !important;
  margin: 0px 20px;
}

.ml-16{
  margin-left: 0px !important;
}
.logo_header> hr{
  background: rgba(180, 184, 222, 0.8) !important;
  z-index: 11;
  margin-top: 20px;
}
/* nav div>div:nth-child(1):not(.shift-card>div){
  position: absolute !important;
  overflow-y: unset !important;
  border-right: none !important;
} */
.custom_header,.custom_header svg{
  color: white;
}
 li:hover svg,li:hover svg.nav-svg path{
  fill: #FF0861 !important;
}
.collapase-menu .customer-menu,.employees-menu .customer-menu,.account-menu .customer-menu,.notification-menu .customer-menu,.product-menu .customer-menu{
  width: 85%;
  margin-left: 17px;
}
/* .collapase-menu{
  margin-bottom: 90px;
} */
/* .account-menu{
  margin-bottom: 235px;
}
.employees-menu{
  margin-bottom: 135px;
}
.notification-menu{
  margin-bottom: 45px;
} */
.custom_header button.Mui-selected svg,.custom_header a.Mui-selected svg, a.Mui-selected svg.nav-svg path{
  fill: #fff !important;
}
.custom_header a{
border-radius: 7px;
}
.custom_header{
  background: #212529 !important;
  box-shadow: unset !important;
}
.custom_header ul{
  margin-top: 20px;
}

.icons img{

  cursor: pointer;
}
.icons{
  display: flex;
  align-items: center;
}
.select_div{
  display: flex;
  justify-content: space-between;
  /* width: 60%; */
}
.select_div .select{
  border: none !important;
}
/* .full_content{
  margin-top: 20rem !important;
} */
.full_content .select>div {
  min-width: 100px;
  border-radius: 1.25rem;
  font-size: 0.785rem;
  color: #99abba;
  display: block;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    /* border: 1px solid #ced4da; */
    border-radius: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 0.7rem !important;
}
.filters .select{
  border-radius: 25px !important;
}
.filters{
  display: flex;
    background: rgba(149, 104, 255, 0.1);
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow-x: auto;
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
}
.filter-adjustment{
  width: 50% !important;
}
nav,nav ul span,nav p{
  font-size: 0.875rem !important; 
  font-family: "roboto-regular", sans-serif;
}

nav ul .MuiListItemIcon-root{
  min-width: 40px !important;
}
.custom_card{
  background: #f6f7ff !important;
  border-radius: 1.5rem;
  min-width: 0;
}
.close-icon{
  display: none !important;
}
.logo_header{
  width: 90%;
  text-align: center;
}
@media(max-width:600px) {
  .custom_header{
  z-index: 1100 !important;
  background: #747bbc !important;
  width: 100% !important;
}

.main-breadcrumb{
  position: unset !important;
  z-index: unset !important;
}
.close-icon{
  display: block !important;
}
.mobile-response .MuiToolbar-gutters{
  display: none;
}
.Mui-selected{
  background-color: unset !important;
}
.mobile-response li{
  padding: 0px 10px !important;
}
.nav-svg{
  width: 20px !important;
}
.nav-svg path{
  fill: #fff;
}
.mobile-response li:hover{
  background: unset !important;
  background-color: unset !important;
}
.shift-card{
  display: none;
}
li button:hover{
  background: unset !important;
}
.logo_header{
  margin-top: 20px !important;
  text-align: center;
  width: 95%;
}
.logo_header svg{
  position: absolute !important;
    right: 15px;
    top: 20px;
}
.logo_header hr{
  margin-top: 20px !important;
}
.mobile-response .MuiDrawer-paperAnchorLeft{
  width: 100% !important;
}
/* .mobile_custom_header{
  z-index: 1100 !important;
  background: #fff !important;
  box-shadow: none !important;
} */
.custom_card{
  margin: 70px 0px !important;
  padding-left: 10px;
  width: 99%;
}
.collapase-menu{
  margin-bottom: auto;
}
.account-menu{
  margin-bottom: auto;
}
.employees-menu{
  margin-bottom: auto;
}
.notification-menu{
  margin-bottom: auto;
}
}
.custom_toggle button{
  padding: 5px 0.5rem !important;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 300;
  min-width: 70px;
  color: #030404;
  text-transform: none;
  border: 1px solid #9568FF;
  font-family: "roboto-regular", sans-serif;
}
.custom_toggle button.Mui-selected{
  background: #9568FF !important;
    color: #fff;
    border: 1px solid #9568FF;
}



.customer-menu{
  display: flex;
  flex-flow: column;
}

.MuiDrawer-paperAnchorLeft{
height: 0px;
}

.mobile-response .MuiDrawer-paperAnchorLeft{
  height: 100%;
    background: #747bbc;
    -webkit-transition: height 0.45s ease-out;
    transition: height 0.45s ease-out;
}

.loginbox{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loginbox input{
  display: block;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 0.785rem;
    font-weight: 400;
    line-height: 1.5;
    color: #666;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2.5rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}
::placeholder{
  color: #666 !important;
}
.loginbox fieldset{
  border: none !important;
}

.loginbox button:hover{
  background:#ff0861;
  color: white;

}

.loginbox button{
  border-color: #ff0861 !important;
  font-size: 0.7rem;
  font-weight: 600;
  color: #000;
}

.custom-btn{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background: #013c89 !important;
    border: #013c89 !important;
    font-weight: 400;
    -webkit-transition: .5s;
    transition: .5s;
    flex: 0 0 auto;
    width: 25%;
    border-radius: unset !important;
    margin-top: 20px !important;
    display: inline-block;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
   
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0px;
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}


/* Manikanta css */

:root{
  --primary : #ff0861;
  --bg-total : #baeefc;
  --bg-unassigned : #fcc4c4;
  --bg-assigned : #acc2f9;
  --bg-intransit : #f9dc93;
  --btn-disable : #a0a6ab;
  --rgba-primary-1 : rgb(209 190 252 / 10%);
}

.btn-primary{
  background: var(--primary) !important;
    color: #fff !important;
    outline: 0;
    border: 0px !important;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0.4rem 1rem !important;
    border-radius: 1rem !important;
    font-size: 0.7rem !important;
}

.btn-primary-outline{
  background: #fff !important;
  border-radius: 1rem !important;
    font-size: 0.7rem !important;
    color: var(--dark) !important;
    border: 1px solid var(--primary) !important;
}
.btn-primary-outline:hover{
  background: var(--primary) !important;
  color: #fff !important;
}

.btn-disabled{
  background-color: var(--btn-disable);
  border: 1px solid #a0a6ab;
    color: #fff;
    outline: 0;
    /* border: 0px; */
    margin-left: 1rem;
    /* margin-right: 1rem; */
    padding: 0.4rem 1rem;
    border-radius: 1rem;
    font-size: 0.7rem;
}
.btn-primary-outline{
  border: 1px solid #ff0861 !important;
    color: #000 !important;
    background: #fff;
    /* color: #99ABBA; */
    outline: 0;
    /* border: 0px; */
    margin-left: 1rem;
    /* margin-right: 1rem; */
    padding: 0.4rem 1rem;
    border-radius: 1rem !important;
    font-size: 0.7rem !important;
}
.btn-primary-outline:hover{
  color: #fff !important;
    background: #ff0861 !important;
}
.bg-total{
  background-color: var(--bg-total);
}
.bg-unassigned{
  background-color: var(--bg-unassigned);
}
.bg-assigned{
  background-color: var(--bg-assigned);
}
.bg-intransit{
  background-color: var(--bg-intransit);
}
.body{
  background: #212529;
  color: #48494D;
  font-family: "roboto-regular", sans-serif;
  font-size: 0.875rem;
}

.main{
  float: right;
  margin-left: 20x;
  background: #212529;
 width: 100%;
 margin: 10px;
}


.logo_header> hr{
  background: rgba(180, 184, 222, 0.8) !important;
  z-index: 11;
  margin-top: 20px;
}
/* nav div>div:nth-child(1):not(.shift-card>div){
  position: absolute !important;
  overflow-y: unset !important;
  border-right: none !important;
} */
.custom_header,.custom_header svg{
  color: white;
}
 li:hover svg,li:hover svg.nav-svg path{
  fill: #FF0861 !important;
}
.collapase-menu .customer-menu,.employees-menu .customer-menu,.account-menu .customer-menu,.notification-menu .customer-menu{
  width: 85%;
  margin-left: 17px;
}
/* .collapase-menu{
  margin-bottom: 90px;
}
.account-menu{
  margin-bottom: 235px;
}
.employees-menu{
  margin-bottom: 135px;
}
.notification-menu{
  margin-bottom: 45px;
} */
.custom_header a{
border-radius: 7px;
}
 /* .App{
  background: #212529 !important;

 } */
 .App{
  background: var(--rgba-primary-1);
 }
.custom_header{
  background: #362465 !important;
  box-shadow: unset !important;
  border-top-right-radius: 2.5rem;
  height: 100%;
}
.custom_header ul{
  margin-top: 20px;
}
.select_div{
  display: flex;
  justify-content: space-between;
  /* width: 75%; */
}
.select_div .select{
  border: none !important;
}
.full_content .select>div {
  min-width: 100px;
  border-radius: 1.25rem;
  font-size: 0.785rem;
  color: #99abba;
  display: block;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    /* border: 1px solid #ced4da; */
    border-radius: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 0.7rem !important;
}
.filters .select{
  border-radius: 25px !important;
}

nav,nav ul span,nav p{
  font-size: 0.875rem !important; 
  font-family: "roboto-regular", sans-serif;
}

nav ul .MuiListItemIcon-root{
  min-width: 40px !important;
}
.custom_card{
  background: #f6f7ff !important;
  /* border-radius: 1.5rem; */
  min-width: 0;
}
.close-icon{
  display: none !important;
}
.logo_header{
  width: 90%;
  text-align: center;
}
@media(max-width:600px) {
  .custom_header{
  z-index: 1100 !important;
  background: #747bbc !important;
  width: 100% !important;
}
.close-icon{
  display: block !important;
}
.mobile-response .MuiToolbar-gutters{
  display: none;
}
.Mui-selected{
  background-color: unset !important;
}
.mobile-response li{
  padding: 0px 10px !important;
}
.nav-svg{
  width: 20px !important;
}
.nav-svg path{
  fill: #fff;
}
.mobile-response li:hover{
  background: unset !important;
}
.shift-card{
  display: none;
}
li button:hover{
  background: unset !important;
}
.logo_header{
  margin-top: 20px !important;
  text-align: center;
  width: 95%;
}
.logo_header svg{
  position: absolute !important;
    right: 15px;
    top: 20px;
    cursor: pointer;
}
.logo_header hr{
  margin-top: 20px !important;
}
.mobile-response .MuiDrawer-paperAnchorLeft{
  width: 100% !important;
}
/* .mobile_custom_header{
  z-index: 1100 !important;
  background: #fff !important;
  box-shadow: none !important;
} */
.custom_card{
  margin: 70px 0px !important;
  padding-left: 10px;
  width: 99%;
}
.collapase-menu{
  margin-bottom: auto;
}
.account-menu{
  margin-bottom: auto;
}
.employees-menu{
  margin-bottom: auto;
}
.notification-menu{
  margin-bottom: auto;
}
}



.customer-menu{
  display: flex;
  flex-flow: column;
}

.MuiDrawer-paperAnchorLeft{
height: 0px;
}

.mobile-response .MuiDrawer-paperAnchorLeft{
  height: 100%;
    background: #747bbc;
    -webkit-transition: height 0.45s ease-out;
    transition: height 0.45s ease-out;
}

.transport_cards{
  width: 100%;
}
.text-center{
  text-align: center;
}

.transport-status{
  padding: 2px 20px;
  border-radius: 50px;
  font-weight: bold !important;
  font-size: 0.85rem !important;
}
.transport-details-title{
  color: #969696;
  font-size: 12px !important;
}
.transport-detail-value{
  font-size: 0.75rem !important;
  font-weight: bold !important;
}
.t-heading{
  font-size: 0.8rem !important;
  text-transform: uppercase;
  font-weight: bold !important;
}
.m-heading{
  font-size: 1rem !important;
  text-transform: uppercase;
  font-weight: bold !important;
  color: #212529 !important;
}
.overview-points{
  display: block !important;
}
.point-value{
  font-weight: bold !important;
    font-size: 1.3rem !important;
}
.fleet-modal{
  margin-top: 1rem !important;
  margin-right: 1rem !important;
}

/* Accounting-Drivers */
.clr-one{
  background: #ffdbdb;
    border-radius: 0.75rem;
    padding: 16px;
}
.clr-two{
  background: #ccdaff;
  border-radius: 0.75rem;
  padding: 16px;
}

.t-value{
  font-weight: 600 !important;
  color: #48494D;
}

.t-title{
  font-weight: 30 !important;
  font-size: 12px !important;
  color: #3c3c3c;
}

/* Notification-Messages */
.css-19kzrtu{
  padding: 0px !important;
}
.tab-content{
  width: 100%;
}
.toggle-tabs{
  border-radius: 0;
  background-color: #fff;
  margin-bottom: 0;
  color: #000;
  padding: 5px 0.5rem;
}
.toggle-tabs:active{
  background: #ff0861;
  border-radius: 0;
  color: #fff;
  border: 1px solid #ff0861;
}

/* Employees-Management */

.full_content .date-picker>div{
  
  color: #99abba;
  
  border-radius: 2.5rem !important;
  width: 60%;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 10px !important;
  font-size: 0.785rem !important;
}
.filters-display{
  display: flex;
  align-items: center;
}
.btn-space{
  margin-right: 4px !important;
}
.table-border{
  border: solid 1px #e8e8e8;
}
/* .full_content .date-picker>div{
  min-width: 100px;
  border-radius: 2.5rem;
  font-size: 0.785rem;
  color: #99abba;
  display: block;
    padding: 10px !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border-radius: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 0.7rem !important;
} */

.btn-upload {
  width: 150px;
  text-align: center;
}
.mt-3{
  margin-top: 0.78rem !important;
}
.bg-heading{
  background: #6c757d!important;
  padding: 12px;
}

/* swiper css */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  /* opacity: 0.4; */
}

.custom-swiper-slider .mySwiper .swiper-slide {
  opacity: 0.4;
}
.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Login css */

:root{
  --primary: #9568FF;
  --secondary: #362465;
  --grey-bg: #f2f4f8;
  --sidebar-bg: #362465;
  --light: #fff;
  --dark: #000;
  --table-head-bg: rgba(149, 104, 255, 0.4);
  --rgba-primary-1: rgb(209 190 252 / 10%);
  --rgba-primary-2: rgba(149, 104, 255, 0.1);
  --box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
  --header-title : 'raleway-bold',sans-serif;
  --auth-btn-bg : #806bbb;
}

.btn-primary{
  background-color: var(--primary) !important;
  color: var(--light) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 0.75rem !important;
}
.btn-primary:hover{
  background-color: var(--auth-btn-bg) !important;
}
.btn-outline-primary{
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 0.75rem !important;
  font-size: 0.7rem !important;
  background: #fff !important;
  text-transform: unset !important;
}
.btn-outline-primary:hover{
  background-color: var(--primary) !important;
  color: var(--light) !important;
}

/* #root{
  background: var(--rgba-primary-2);
  height: 100vh;
  overflow-y: scroll;
} */
.card-content{
  width: 100%;
  padding: 36px !important;
}
.clr-primary{
  color: var(--primary);
}
.btn-primery.active {
  background: var(--primary);
  color: var(--light) !important;
}
.btn-clr-main {
  background-color: #806bbb !important;
}
.btn-clr-main:hover {
  background-color: #503696 !important;
}
._btn-outline:hover {
  color: var(--light);
  background: var(--primary);
}
.hvr-grow-shadow {
  vertical-align: middle;
  /* -webkit-transform: perspective(1px) translateZ(0); */
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  /* -webkit-transition-duration: 0.3s; */
  transition-duration: 0.3s;
  /* -webkit-transition-property: box-shadow, transform; */
  transition-property: box-shadow, transform;
}
.hvr-grow-shadow:hover{
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  /* -webkit-transform: scale(1.1); */
  /* transform: scale(1.1); */
}

.btn-primery {
  background: var(--light);
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 0.75rem !important;
  padding: 0.4rem 1rem !important;
}
.login-btn{
  text-decoration: none;
}
._btn-outline:hover {
  color: var(--light);
  background: var(--primary);
}

.btn-primery:hover {
  background: var(--primary) !important;
  color: var(--light) !important;
}
.frgt-pswrd {
  color: #9568ff !important;
  text-decoration: underline;
}
/* .Absolute-Box{
  padding-top: 50px;
}
 */
 .Absolute-Box{
  margin-top: 6rem;
 }
 .bg-secondary{
  background: rgba(149, 104, 255, 0.1);
  padding: 12px;
  
 }
 .p-info{
  color: #362465 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
 }